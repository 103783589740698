import { Log } from 'helpers/logger';
import currencyFormatter from 'currency-formatter';

export const ArtificialDelay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const isIterableArray = array => Array.isArray(array) && !!array.length;

export const FormatCurrency = (value, currency) => currencyFormatter.format(Number(value), { code: currency.toUpperCase() });

export const ScrollTop = () => {
    if(window.self !== window.top) {
        window.parent.postMessage(JSON.stringify({action: "kk238gj3428"}),'*');
    } else {
        window.scrollTo(0,0);
    }
}

export const ScrollBottom = () => window.scrollTo(0,document.body.scrollHeight);

export const GetParameter = (key) => {
    const d = (new URLSearchParams(window.location.search)).get(key)
    return d !== '' ? d : false;
}

export const UnmountComponent = (component) => {
    return () => {
        component.current = false
    }
}

export const GetTranslation = (key) => {
    return key.en;
}

export const GenerateRandString = (length) => {
   let result           = '';
   const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
   const charactersLength = characters.length;
   for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

export const ApplyCSS = function(bodyBackgoundColor, headerFontColor){

    try {
        const color1 = bodyBackgoundColor ? '#' + bodyBackgoundColor.replace('#','') : '#f2f5ff';
        const color2 = headerFontColor ? '#' + headerFontColor.replace('#','') : '#5e6e82';

        let style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = 'body {background-color: '+color1+' !important} .header-font-color, .powered-by a, .powered-by a:link, .powered-by a:hover, .powered-by a:visited, .powered-by a:active {color: '+color2+' !important}';
        document.getElementsByTagName('head')[0].appendChild(style);
    } catch(e){
        // not essential, no need to log this
    }
}

export const getItemFromStore = (key, defaultValue) =>
  JSON.parse(getCookie(key)) || defaultValue;

export const setItemToStore = (key, payload) => setCookie(key, JSON.stringify(payload), 30);

export const GenerateUUID = function() {
    try {
        const cookie = getCookie('ws423dkf238saf');
        if(cookie !== null) {
            window.widget_uuid = cookie;
            return cookie;
        }
        let buf = new Uint32Array(4);
        window.crypto.getRandomValues(buf);
        let idx = -1;
        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            idx++;
            let r = (buf[idx>>3] >> ((idx%8)*4))&15;
            let v = c === 'x' ? r : ((r&0x3)|0x8);
            return v.toString(16);
        });
        setCookie('ws423dkf238saf', uuid, 5);
        window.widget_uuid = uuid;
        return uuid;
    } catch(e){
        const uuid = GenerateRandString(16);
        setCookie('ws423dkf238saf', uuid, 5);
        window.widget_uuid = uuid;
        return uuid;
    }
};

export const setCookie = function(name,value,days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export const getCookie = function(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export const CalculateOrder = (states) => {
    let productsTotal = 0;
    let orderTotal = 0;
    let productFees = {};

    for(const key in states.event.products) {
        const product = states.event.products[key];
        if(!states.productsSelected.includes(product.uuid)) continue;
        const unitPrice = parseInt(product.productSummary.unitPrice);
        productsTotal += parseInt(product.quantitySelected) * unitPrice;
        orderTotal += parseInt(product.quantitySelected) * unitPrice;
    }
    return {
        productsTotal: productsTotal,
        orderTotal: orderTotal,
        productFees: productFees
    }
}


export const ErrorHandler = (e, s, p) => {
    console.log('ERROR', s);
    console.log(e);
    console.log(p);
    Log({
        'status': 'error',
        'name': 'widget',
        'message': 's',
        'opts': {
            'event': '',
            'note': 'errorCaught',
            'error': {
                'msg': e.msg,
                'src': e.src,
                'lineno': e.lineno,
                'colno': e.colno,
                'error': e.error
            }
        }
    });
}
