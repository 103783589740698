import React, { createContext, useState } from 'react';
const AppContext = createContext({ });

export const Provider = props => {
    const [isLoaded, setIsLoaded] = useState(false);
   
    const value = {};

    if (!isLoaded) {
        const styleSheet = document.createElement('link');
        styleSheet.href = `${process.env.PUBLIC_URL}/css/theme.css`;
        styleSheet.type = 'text/css';
        styleSheet.rel = 'stylesheet';
        styleSheet.className = 'theme-stylesheet';        
        styleSheet.onload = setIsLoaded(true);
        document.getElementsByTagName('head')[0].appendChild(styleSheet);
        return (
            <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: '#f2f5ff' }} />
        );
    }
    return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};

export default AppContext;