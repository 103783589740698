import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faTicketAlt,
    faUser,
    faDollarSign,
    faThumbsUp,
    faChevronRight,
    faChevronLeft,
    faTimes,
    faQuestionCircle,
    faExclamationCircle,
    faLock
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faTicketAlt,
    faUser,
    faDollarSign,
    faThumbsUp,
    faChevronRight,
    faChevronLeft,
    faTimes,
    faQuestionCircle,
    faExclamationCircle,
    faLock        
);
