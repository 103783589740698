import { useEffect } from 'react';

export const LoadPaymentsHelper = () => {
  useEffect(() => {
    (function (global, namespace) {
      const paymentUrlRegex = /\?paymentId=(.+)$/;

      const knownEvents = [
        'refresh-token',
        'response',
        'payment-succeeded',
        'done',
        'payment-failed-final',
        'payment-failed-retry',
        'loading-invoice',
        'invoice-loaded',
        'done',
        'ready-for-user-action',
        'submitting-payment-info',
        'processing-payment',
        'done',
        'submitting-payment-info',
        'processing-payment',
        'done',
        'ready',
        'done',
        'style-changed-height',
        'ready-for-localization',
        'update-localization',
      ];

      function CBPayments(agent, paymentLink, onDone) {
        this._domain = paymentLink[0];
        this._window = null;
        this._agent = agent;
        this._iframe = null;
        const paymentId = paymentLink[1];
        const paymentId_match = paymentId.match(paymentUrlRegex);
        this._paymentId = paymentId_match ? paymentId_match[1] : paymentId;
        this._onDone = onDone;
        this._eventHandler = null;

        this._nextResponseToken = 1;
        this._responseCallbackMap = {};

        this.$on_message = this._on_message.bind(this);
        window.addEventListener('message', this.$on_message);
      }

      CBPayments.prototype.setEventHandler = function (handler) {
        this._eventHandler = handler;
        return this;
      };

      CBPayments.prototype.destroy = function () {
        if (this.$on_message) {
          window.removeEventListener('message', this.$on_message);
          this.$on_message = null;
        }
        if (this._iframe) {
          this._iframe.parentNode.removeChild(this._iframe);
          this._iframe = null;
        }
        window.paymentsHelper = false;
      };

      CBPayments.prototype.iframe = function (callback) {
        if (this._window) {
          throw new Error('already opened');
        }
        return this.getUrl().then(
          function (url) {
            if(this._iframe) this._iframe.destroy();
            const iframe = (this._iframe = document.createElement('iframe'));
            iframe.id = 'cb-payment-iframe';
            iframe.width = '100%';
            iframe.src = url;
            iframe.onload = callback;
            iframe.frameBorder="0";
            iframe.style.opacity = '0';
            document.getElementById('cb-payment-container').appendChild(iframe);
            iframe.style.width='100%';
            iframe.style.height='606px';
            iframe.style.minHeight='606px';
            this.bindWindow(iframe.contentWindow);
            return iframe;
          }.bind(this)
        );
      };

      CBPayments.prototype.getUrl = function () {
        const domain = this._domain;
        return this._agent
          .generateAuthenticationJCT({
            o: window.location.origin,
            aud: `${domain}/api`
          }, 30)
          .then(
            function (jct) {
              return (
                [domain, '/select-payment-method.html?paymentId=', this._paymentId, '&t=', new Date().getTime(), '#!!', jct].join('')
              );
            }.bind(this));
      };

      CBPayments.prototype.bindWindow = function (target) {
        this._window = target;
      };

      CBPayments.prototype.postMessage = function (event, payload, onResponse, replyId) {
        const message = { event: event, payload: payload };
        if (typeof onResponse === 'function') {
          message.responseId = this._nextResponseToken++;
          this._responseCallbackMap[message.responseId] = onResponse;
        }
        if (replyId) {
          message.replyId = replyId;
        }
        this._window.postMessage(message, this._domain);
      };

      CBPayments.prototype._on_message = function (message) {
        if (message.origin === this._domain && (message.data.event && (knownEvents.includes(message.data.event) || typeof eventHandlers[message.data.event] === 'function'))) {
          if(message.data.event === 'ready-for-user-action'){
            document.getElementById('cb-payment-iframe').style.opacity = '1';
          }
          if (typeof eventHandlers[message.data.event] === 'function') {
            eventHandlers[message.data.event](message.data, this);
          }
          if (typeof this._eventHandler === 'function') {
            this._eventHandler(message.data.event, message.data.payload);
          }
        }
      };

      const eventHandlers = {
        ready: function (data, cbPayments) {
          cbPayments.postMessage('process-payment', {
            paymentId: cbPayments._paymentId
          });
        },
        done: function (data, cbPayments) {
          if (!data.payload.success) {
            console.error(namespace, data.payload.error_code, data.payload.error);
          }
          cbPayments._window.close();
          if (typeof cbPayments._onDone === 'function') {
            cbPayments._onDone(data.payload);
          }
          cbPayments.destroy();
        },

        'refresh-token': function (data, cbPayments) {
          cbPayments._agent
            .generateAuthenticationJCT({
              aud: `${cbPayments._domain}/api`
            }, 15)
            .then(function (token) {
              cbPayments.postMessage(
                'response',
                { token: token },
                null,
                data.responseId
              );
            });
        },

        response: function (data, cbPayments) {
          if (typeof cbPayments._responseCallbackMap[data.replyId] === 'function') {
            cbPayments._responseCallbackMap[data.replyId](data);
            delete cbPayments._responseCallbackMap[data.replyId];
          } else {
            console.error(namespace, 'Invalid replyId received', data);
          }
        }
      };
      global[namespace] = CBPayments;
    })(window, 'CBPaymentsHelper');
    return () => {
      //window['CBPaymentsHelper'].destroy();
    }
  }, []);
};
