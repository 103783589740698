import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getItemFromStore } from 'helpers/functions';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        lng: getItemFromStore('lang', 'en'),
        fallbackLng: 'en',
        keySeparator: '.',
        objectNotation: true,
        backend: {
            loadPath: 'locales/{{lng}}.json'
        },
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true
        }
    });

export default i18n;