import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'helpers/events';

import React, { Suspense, useEffect } from 'react';
import { Provider } from 'helpers/context';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { GenerateUUID, GetParameter, ApplyCSS } from 'helpers/functions';
import { LoadPaymentsHelper} from 'helpers/api/payments';
import ReactDOM from 'react-dom';
import loadable from '@loadable/component';
import PageLoader from 'components/common/PageLoader';
import 'helpers/fontAwesome';
import 'helpers/localization';

const Widget = loadable(() => import('layouts/Widget'));

const App = () => {
    const uuid = GenerateUUID();
    useEffect(() => Widget.preload, []);

    LoadPaymentsHelper();
    ApplyCSS(GetParameter('c1'), GetParameter('c2'));

    console.log(
        'Intellitix Cloud', '|',
        'Environment: ', process.env.REACT_APP_ENV.toUpperCase(), '|',
        'Version: ', `${process.env.REACT_APP_VERSION}, build: ${process.env.REACT_APP_BUILD}`, '|',
        'Session: ', uuid
    );

    return (
        <Provider>
            <Suspense fallback={<PageLoader states={{invalid: false}} failed="" />}>
                <Router basename={process.env.PUBLIC_URL}>
                    <Router fallback={<span />}>
                        <Switch>
                            <Route path="/" component={Widget} />
                        </Switch>
                    </Router>
                </Router>
            </Suspense>
        </Provider>
    );
}

ReactDOM.render(
    <App />,
    document.getElementById('main')
);
