const axios = require("axios");

export const Log = async ({ status, page, action, name, message, opts }) => {
    try {
        const isOptsValdid = typeof opts === 'object' && opts !== null;
        
        let payload = {
            env: process.env.REACT_APP_ENV,
            app: 'cb-widget',
            status: status,
            session: window.widget_uuid,
            page: page,
            action: action,
            name: name,
            message: message
        }
        
        if(isOptsValdid && Object.keys(opts).length > 0)
            payload['opts'] = opts;
            
        axios({
            method: 'post',
            url: 'https://insight.crowdblink.com/la',
            data: payload
        }).then((res) => {
            //console.log(res.data);
        }, (error) => {
            console.log(error);
        });
    } catch(e){}
    
    return;
}