import React from 'react';
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const PageLoader = ({ states, failed }) => {
    return (
        <Row className="flex-center min-vh-100 py-6 row" style={{ alignItems: 'flex-start' }}>
            <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4" style={{ maxWidth: states.invalid === true ? '400px' : '320px' }}>
                <Card className="m-center text-center">
                    <CardBody className="fs--1 font-weight-normal p-5">
                        <Row>
                            {states.invalid === false ? (
                                <Spinner animation="border" color="primary" style={{ width: '75px', height: '75px', margin: '0px auto' }} />
                            ) : (
                                <FontAwesomeIcon icon="exclamation-circle" style={{ width: '75px', height: '75px', margin: '0px auto', color: 'red' }} />
                            )}
                        </Row>
                        {states.invalid === true && (
                            <h4 className="mt-4">
                                {failed}
                            </h4>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

PageLoader.propTypes = {
    states: PropTypes.object.isRequired,
    failed: PropTypes.string.isRequired
};

export default PageLoader; 