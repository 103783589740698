import { Log } from 'helpers/logger';

// give 'er a few extra pixels just in case
const extraPadding = 50;
window.renderHeight = 0;

setInterval(function() {
    try {
        const el = window.document.getElementById('watch-resize');
        if(window.renderHeight !== el.getBoundingClientRect().height){
            window.parent.postMessage(JSON.stringify({action: "skl4820592jfal", height: (el.getBoundingClientRect().height + extraPadding)}),'*');
            //console.log('Widget Height', el.getBoundingClientRect().height, window.renderHeight, window.renderHeight.child);
            window.renderHeight = el.getBoundingClientRect().height;
        }
    } catch(e){
        //console.log(e);
    }
}, 50);

window.onerror = function(msg, src, lineno, colno, error) {
    Log({
        'status': 'error', 
        'name': 'widget', 
        'message': 'windowError', 
        'opts': {
            'url': window.location.href,
            'error': {
                'msg': msg,
                'src': src,
                'lineno': lineno,
                'colno': colno,
                'error': error
            }
        }
    });
    return false;
}